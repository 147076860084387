import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/auth/user.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CDOverlayRef, DIALOG_DATA } from 'src/app/services/overlay/overlay.service';
import { NavigationService } from 'src/app/services/routing/navigation.service';
import { UserSettingsService } from 'src/app/services/settings/user-settings.service';

type SubMenu = 'links' | 'settings' | 'pdfs';

/**
 * Used for version
 */
const appInfo = require('../../../../../package.json');

@Component({
  selector: 'app-app-drawer',
  templateUrl: './app-drawer.component.html',
  styleUrls: ['./app-drawer.component.scss']
})
export class AppDrawerComponent implements OnInit, AfterViewInit {

  @ViewChild('closeButton') closeButtonRef: ElementRef<HTMLButtonElement>;

  links: { display: string, url: string }[] = [];
  pdfs: { display: string, url: string }[] = [];
  favoritesText: string;

  blankJobBriefUrl: string;

  subMenu: SubMenu;

  constructor(
    private dialogRef: CDOverlayRef,
    @Inject(DIALOG_DATA) public data: any,
    private firebase: FirebaseService,
    private userService: UserService,
    private userSettings: UserSettingsService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    // $_FORM_TYPE_ADJUSTMENT_$ -> Sidebar links
    switch (this.data.userType) {
      case 'line':
        this.favoritesText = 'Crew Favorites';
        this.blankJobBriefUrl = '/forms/print?type=line';
        this.links = [
          {
            display: 'Report a Good Catch',
            url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=OjjeLh9-V0OoRoWIaywMTR-azEc1zANMs-VwUh9HJy5UQTU1WTJITDYzQzFVM1BKN1lOVkpGV1hHUi4u&wdLOR=cA02BB18E-E2AC-4488-9C3C-B6BF6001EE74'
          },
          {
            display: 'Quick Fit',
            url: 'https://dukeenergy.sharepoint.com/sites/EventFreeOps/Shared%20Documents/Safety%20Topic%20Library/Ergonomics%20and%20Quick%20Fit/Quick%20Fit%20for%20ePJB/QF%20PDF.pdf?web=1'
          },
          {
            display: 'Take 10 Daily Briefing',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Individual%20Work%20Methods/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F1%2E1%20Job%20Briefings%2FRelated%20Documents%2FTake%2010%20Daily%20Briefing%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F1%2E1%20Job%20Briefings%2FRelated%20Documents'
          },
          {
            display: 'Tools & Work Methods',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM'
          },
          {
            display: 'Energized vs De-energized Decision-making Guide',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Decision%20Guides/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FDecision%20Guides%2FEnergized%20vs%2E%20De%2Denergized%20Decision%2Dmaking%20Guide%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FDecision%20Guides'
          },
          {
            display: 'TESS- Trenching, Excavation, Shoring and Sloping Guidance',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Individual%20Work%20Methods/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F9%2E4%20Trenching%20%26%20Excavations%2FRelated%20Documents%2FTrenching%2C%20Excavation%2C%20Shoring%20%26%20Sloping%20%28TESS%29%20Guidance%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F9%2E4%20Trenching%20%26%20Excavations%2FRelated%20Documents'
          },
          {
            display: 'Keys to Life',
            url: 'https://dukeenergy.sharepoint.com/sites/portal_safety/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fportal%5Fsafety%2FShared%20Documents%2FCustomer%20Delivery%20Keys%20to%20Life%2Epdf&parent=%2Fsites%2Fportal%5Fsafety%2FShared%20Documents'
          },
          {
            display: 'Safety Imperatives',
            url: 'https://dukeenergy.sharepoint.com/sites/EventFreeOps/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=BZOrU6&cid=9bf91976%2D561d%2D478d%2D85fa%2Ddef293a6d2b5&FolderCTID=0x012000ADF755502B98B042AA6A8E2DEF0ABEA0&id=%2Fsites%2FEventFreeOps%2FShared%20Documents%2FSafety%20Imperatives%2FSafety%20Imperatives%20Poster%2Epdf&parent=%2Fsites%2FEventFreeOps%2FShared%20Documents%2FSafety%20Imperatives'
          },
          {
            display: 'CSDR- Cardinal Safe Digging Rules',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Individual%20Work%20Methods/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F9%2E4%20Trenching%20%26%20Excavations%2FRelated%20Documents%2FCardinal%20Safety%20Digging%20Rules%20%28CSDR%29%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F9%2E4%20Trenching%20%26%20Excavations%2FRelated%20Documents'
          },
          // {
          //   display: 'Environmental',
          //   url: 'https://dukeenergy.sharepoint.com/sites/portal_customer-delivery/SitePages/Safety-Environmental-Materials.aspx#safety-environmental-materials-3'
          // },
          {
            display: 'High Risk Tools',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Individual%20Work%20Methods/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F5%2E1%20High%2DRisk%20Tools%2FWM%205%2E1%20High%2DRisk%20Tools%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F5%2E1%20High%2DRisk%20Tools'
          },
          {
            display: 'URP- Underground Repair Plan',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Individual%20Work%20Methods/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F9%2E4%20Trenching%20%26%20Excavations%2FRelated%20Documents%2FUnderground%20Repair%20Plan%20%28URP%29%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FIndividual%20Work%20Methods%2F9%2E4%20Trenching%20%26%20Excavations%2FRelated%20Documents'
          },
          {
            display: 'SC&L- High-Energy Hazard Wheel',
            url: 'https://dukeenergy.sharepoint.com/sites/portal_safety/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fportal%5Fsafety%2FShared%20Documents%2FEnergy%20Wheel%2DOne%20Page%2Epdf&parent=%2Fsites%2Fportal%5Fsafety%2FShared%20Documents'
          },
          // {
          //   display: 'Fatigue Assessment Guide',
          //   url: 'https://team.duke-energy.com/sites/CO-WPD/TWM-P/Shared%20Documents/Fatigue%20Assessment%20Guide.pdf'
          // },
          {
            display: 'Off-Road Accessibility Checklist',
            url: 'https://dukeenergy.sharepoint.com/:b:/r/sites/CD-TWM/Individual%20Work%20Methods/7.3%20Digger%20Derrick%20Operations/Related%20Documents/Off-Road%20Accessibility%20Checklist.pdf?csf=1&web=1&e=wylOU4'
          },
          {
            display: 'Grounding Decision Guide',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM/Decision%20Guides/Forms/AllItems.aspx?id=%2Fsites%2FCD%2DTWM%2FDecision%20Guides%2FGrounding%20Decision%20Guides%2Epdf&parent=%2Fsites%2FCD%2DTWM%2FDecision%20Guides'
          },
          {
            display: 'Vehicle Recovery Decision Guide',
            url: 'https://dukeenergy.sharepoint.com/:b:/r/sites/CD-TWM/Decision%20Guides/Vehicle%20Recovery%20Decision%20Guide.pdf?csf=1&web=1&e=jEF7nr&xsdata=MDV8MDJ8fGNlNjk5NTY0OGIwZTRiY2FkMDZkMDhkYzg1NzIxYjI5fDJlZGUzODNhN2UxZjQzNTdhODQ2ODU4ODZiMmMwYzRkfDB8MHw2Mzg1MzE5NzIzMDgyNTg5MjV8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbUpsWkRBMVltUTFZV1UzTWpSaVlUVTVabVkwTXprMFlqRXdNekF5WkdSaVFIUm9jbVZoWkM1Mk1pOXRaWE56WVdkbGN5OHhOekUzTmpBd05ESTVOakl5fDgwNjI5YjI2YjFlNDRiNTJkMDZkMDhkYzg1NzIxYjI5fGI1NjJjNzE2NTQyMDQxYzM4ODhiNzVhOTYwNzE5NGYy&sdata=dDRLcjFuUXlTNklLS0JhK3o5UUNBeFl4aW9VQ2ZzajM4RTFIQzR0aE1wdz0%3D&ovuser=2ede383a-7e1f-4357-a846-85886b2c0c4d%2CLindsay.Czopur%40duke-energy.com'
          }
        ];
        break;
      case 'fleet':
        this.favoritesText = 'Tech Favorites';
        this.blankJobBriefUrl = '/forms/print?type=fleet';
        this.links = [
          {
            display: 'Report a Good Catch',
            url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=OjjeLh9-V0OoRoWIaywMTR-azEc1zANMs-VwUh9HJy5UQTU1WTJITDYzQzFVM1BKN1lOVkpGV1hHUi4u&wdLOR=cA02BB18E-E2AC-4488-9C3C-B6BF6001EE74'
          },
          {
            display: 'Quick Fit',
            url: 'https://dukeenergy.sharepoint.com/sites/EventFreeOps/Shared%20Documents/Safety%20Topic%20Library/Ergonomics%20and%20Quick%20Fit/Quick%20Fit%20for%20ePJB/QF%20PDF.pdf?web=1'
          },
          {
            display: 'Keys to Life',
            url: 'https://team.duke-energy.com/sites/Fleet/_layouts/15/WopiFrame2.aspx?sourcedoc={b7c151d8-bdb0-4922-b245-f2c60b87a4ee}&action=edit&source=https:%2F%2Fteam.duke-energy.com%2Fsites%2FFleet%2FSafety%2FForms%2FAllItems.aspx%3FRootFolder%3D%252Fsites%252FFleet%252FSafety%252FKeys%2520to%2520Life%26FolderCTID%3D0x012000E6DDA0421CC94A4B9BDBE9C6AE6A8EAE%26View%3D%257BC898E8FD%252DAE0D%252D4A4E%252DBB08%252DC97E64F0340F%257D'
          },
          {
            display: 'Tools & Equipment Requiring Hearing Protection',
            url: 'https://team.duke-energy.com/sites/Fleet/_layouts/15/WopiFrame2.aspx?sourcedoc={577b6e3f-be41-4ca4-bb57-85e880299d7b}&action=edit&source=https:%2F%2Fteam.duke-energy.com%2Fsites%2FFleet%2FSafety%2FForms%2FAllItems.aspx%3FRootFolder%3D%252Fsites%252FFleet%252FSafety%252FFleet%2520Safety%2520Policies%26FolderCTID%3D0x012000E6DDA0421CC94A4B9BDBE9C6AE6A8EAE%26View%3D%257BC898E8FD%252DAE0D%252D4A4E%252DBB08%252DC97E64F0340F%257D'
          },
          {
            display: 'Safety Policies',
            url: 'https://team.duke-energy.com/sites/Fleet/_layouts/15/WopiFrame2.aspx?sourcedoc={1eb9fd6e-2bad-495a-bb43-f0f29d4f738c}&action=edit&source=https:%2F%2Fteam.duke-energy.com%2Fsites%2FFleet%2FSafety%2FForms%2FAllItems.aspx%3FRootFolder%3D%252Fsites%252FFleet%252FSafety%252FFleet%2520Safety%2520Policies%26FolderCTID%3D0x012000E6DDA0421CC94A4B9BDBE9C6AE6A8EAE%26View%3D%257BC898E8FD%252DAE0D%252D4A4E%252DBB08%252DC97E64F0340F%257D'
          },
          {
            display: 'Critical Component Actions Policy',
            url: 'https://team.duke-energy.com/sites/Fleet/Policy%20%20Procedure/Fleet%20Services%20Wheel%20Bearing%20Component%20and%20Maintenance_Oct%202016.pdf'
          },
          {
            display: 'PPE Assessment Chart',
            url: 'https://team.duke-energy.com/sites/Fleet/_layouts/15/WopiFrame.aspx?sourcedoc=%7B321c8e45-b379-4e7c-9697-67545fedfcaf%7D&action=edit&source=https%3A%2F%2Fteam%2Eduke%2Denergy%2Ecom%2Fsites%2FFleet%2FSafety%2FForms%2FAllItems%2Easpx%3FRootFolder%3D%252Fsites%252FFleet%252FSafety%252FFleet%2520Safety%2520Policies%26FolderCTID%3D0x012000E6DDA0421CC94A4B9BDBE9C6AE6A8EAE%26View%3D%257BC898E8FD%252DAE0D%252D4A4E%252DBB08%252DC97E64F0340F%257D'
          }
        ];
        break;
      case 'non-craft':
        this.favoritesText = 'Crew Favorites';
        this.blankJobBriefUrl = '/forms/print?type=non-craft';
        this.links = [
          {
            display: 'Report a Good Catch',
            url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=OjjeLh9-V0OoRoWIaywMTR-azEc1zANMs-VwUh9HJy5UQTU1WTJITDYzQzFVM1BKN1lOVkpGV1hHUi4u&wdLOR=cA02BB18E-E2AC-4488-9C3C-B6BF6001EE74'
          },
          {
            display: 'Quick Fit',
            url: 'https://dukeenergy.sharepoint.com/sites/EventFreeOps/Shared%20Documents/Safety%20Topic%20Library/Ergonomics%20and%20Quick%20Fit/Quick%20Fit%20for%20ePJB/QF%20PDF.pdf?web=1'
          }
        ];
        break;
      case 'transmission':
        this.favoritesText = 'Crew Favorites'
        // this.blankJobBriefUrl = '/forms/print?type=non-craft'
        this.links = [
          {
            display: 'C&M Keys to Life',
            url: 'https://dukeenergy.sharepoint.com/:b:/r/sites/portal_safety/Shared%20Documents/Transmission%20Construction%20and%20Maintenance%20Keys%20to%20Life.pdf?csf=1&web=1&e=a32o7I'
          },
          {
            display: 'CESR',
            url: 'https://cast-links.duke-energy.app/pro/Cardinal+Electrical+Safety+Rules+Guidance.pdf?t=tandd-pro&vsid=089de4fb-4307-c8a9-8ab4-9271cd500000&id=089de4fb-4307-c8a9-8ab4-9271cd500000_1.0.0&aid=C44DD007-A4BE-4DBD-8CEB-D0D079A66924&versionBehavior=LatestReleasedVersion'
          },
          {
            display: 'Confined Space Entry Permit',
            url: 'https://cast-links.duke-energy.app/pro/Entry+Permit2.pdf?t=tandd-pro&vsid=223d5e96-1e5f-4d5e-9acd-b178ffbbb191&id=223d5e96-1e5f-4d5e-9acd-b178ffbbb191_3.0.0&aid=0B1B705B-3CA9-4358-9668-E060781B6774&versionBehavior=LatestReleasedVersion'
          },
          {
            display: 'Confined Space Evaluation Form',
            url: 'https://cast-links.duke-energy.app/pro/Confined+Space+Hazard+Evaluation.pdf?t=tandd-pro&vsid=84fa042e-d739-45a6-b698-97b8cd6cecb6&id=84fa042e-d739-45a6-b698-97b8cd6cecb6_3.0.0&aid=C1BACFF0-0D07-4E1E-BABD-FA4E90259E56&versionBehavior=LatestReleasedVersion'
          },
          {
            display: 'EHS Handbook',
            url: 'https://cast-links.duke-energy.app/pro/2023+EHS+Handbook.pdf?t=tandd-pro&vsid=6909ec01-b816-c780-8ee3-695df4c00000&id=6909ec01-b816-c780-8ee3-695df4c00000_3.0.0&aid=A21EFBCB-EDBB-492E-B607-B4A3451BC5B1&versionBehavior=LatestReleasedVersion'
          },
          {
            display: 'High-Risk Tool Addendum Form',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/High-Risk%20Tools%20Job%20Brief%20Addendum%20Form_08-11-20_R1.pdf'
          },
          {
            display: 'Hot Stick Form',
            url: 'https://p8corp-fusion.duke-energy.com/FusionP8/EntryPoint.aspx?Server=p852Prod&ExternalAction=Search&ObjectStore=EnterpriseFusion&Class=Administrative&SearchType=Released&ContentType=Rendition&EnableSingleLaunch=1&DocumentNumber=STDF-SAF-TRM-00007'
          },
          {
            display: 'Hot Work Permit',
            url: 'https://cast-links.duke-energy.app/pro/ADMF-SAF-HSF-00036+Hot+Work+Permit.pdf?t=tandd-pro&vsid=cbd64cda-ed68-46c4-bdcc-f1e252c4581b&id=cbd64cda-ed68-46c4-bdcc-f1e252c4581b_7.0.0&aid=3C611AE4-5766-470F-8925-BD8D66D6A4E3&versionBehavior=LatestReleasedVersion'
          },
          {
            display: 'HP Principles',
            url: 'https://dukeenergy.sharepoint.com/sites/THP'
          },
          {
            display: 'Hydration Guidelines',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TSI/Documents/Hydration%20Guidelines/Duke%20Energy%20Hydration%20Guidelines-Final.pdf'
          },
          {
            display: 'Lightning Guidance',
            url: 'https://cast-links.duke-energy.app/pro/GDLP-SAF-TRM-00002+-+Rev.+000.pdf?t=tandd-pro&vsid=887411da-aaa8-41b7-ac9b-0d5b64cfc817&id=887411da-aaa8-41b7-ac9b-0d5b64cfc817_1.0.0&aid=7001AF48-1996-4725-B52B-1108F790A3EC&versionBehavior=LatestReleasedVersion'
          },
          {
            display: 'Non-DOT Checklist(ATV/UTVs,etc.)',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TSI/Documents/Daily%20Equipment%20Checklist/Daily%20Equipment%20Checklist%20-%20Guideline%20version.xlsx'
          },
          {
            display: 'Office Keys to Life',
            url: 'https://dukeenergy.sharepoint.com/:b:/r/sites/portal_safety/Shared%20Documents/Transmission%20Office%20Keys%20to%20Life.pdf?csf=1&web=1&e=E96RXR'
          },
          {
            display: 'Quick Fit',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TSI/Documents/Quick%20Fit/Transmission%20Quick%20Fit%20Essentials%202022.pdf'
          },
          {
            display: 'Safety Ground Record Form',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/Safety%20Ground%20Record%20Form%20(With%20Instructions).pdf'
          },
          {
            display: 'SC&L- High-Energy Hazard Wheel',
            url: 'https://dukeenergy.sharepoint.com/sites/portal_safety/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fportal%5Fsafety%2FShared%20Documents%2FEnergy%20Wheel%2DOne%20Page%2Epdf&parent=%2Fsites%2Fportal%5Fsafety%2FShared%20Documents'
          },
          {
            display: 'Substation Entry Policy',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Dashboard%20Document%20Library/Transmission%20Substation+Switchyard%20Entry%20Policy.pdf'
          },
          {
            display: 'Substation IVR',
            url: 'https://apps.powerapps.com/play/e/271741b4-65e7-4f9b-8619-9aea197fcfdb/a/f9f42add-5663-4f53-9fbf-c4026f44fc2c?tenantId=2ede383a-7e1f-4357-a846-85886b2c0c4d'
          },
          {
            display: 'Tool & Work Methods',
            url: 'https://dukeenergy.sharepoint.com/sites/CD-TWM'
          }
        ];
        this.pdfs = [
          {
            display: 'Transmission Line',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/Pre-Job%20Brief%20Form%20-%20Line.aspx'
          },
          {
            display: 'Relay',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/Pre-Job%20Brief%20Form%20-%20Relay.aspx'
          },
          {
            display: 'Substation',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/Pre-Job%20Brief%20Form%20-%20Substation.aspx'
          },
          {
            display: 'Heavy Hauling',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/Pre-Job%20Brief%20Form%20-%20Heavy%20Hauling.aspx'
          },
          {
            display: 'Non-Discipline',
            url: 'https://team.duke-energy.com/sites/CO-Trans/TWM/Work%20Methods%20Public%20Library/Pre-Job%20Brief%20Form%20-%20Non-Discipline.aspx'
          }
        ]
        break;

    }
  }

  ngAfterViewInit(): void {
    this.closeButtonRef?.nativeElement?.focus();
  }

  get version() {
    return appInfo.version;
  }

  openSubMenu(subMenu: SubMenu) {
    this.subMenu = subMenu;
  }

  closeSubMenu(pdfSubMenu?: SubMenu) {
    this.subMenu = pdfSubMenu === 'pdfs' ? 'links' : undefined;
  }

  linkClicked(link: string) {
    this.firebase.logEvent(`${link} app drawer link clicked`);
    this.dialogRef.close();
  }

  promptUserType() {
    this.linkClicked('Change User Type');
    this.userSettings.promptUserTypeAndUpdateSettings().subscribe(
      () => this.navigationService.replaceLocationWithMyFormsPage()
    );
  }

  logout() {
    this.firebase.logEvent('logout');
    this.userService.logout();
  }

  close() {
    this.firebase.logEvent('close app drawer with button');
    this.dialogRef.close();
  }
}
